<template>
  <div v-click-outside="{ handler: close }" class="app-select" :class="{ open: isOpen }">
    <div class="app-select-header" @click="toggle">
      <slot name="icon">
        <ISort width="14" />
      </slot>
      <span>
        <slot name="name" v-bind="activeItem">{{ activeItem.name }}</slot>
      </span>
      <IArrowDown width="12" class="app-select-arrow" />
    </div>
    <transition>
      <div v-if="isOpen" class="app-select-body">
        <a
          v-for="item in items"
          :key="item.value"
          href="javascript:void(0)"
          class="app-select-item"
          @click.prevent="selectItem(item.value)"
        >{{ item.name }}</a>
      </div>
    </transition>
  </div>
</template>
<script>
import IArrowDown from "../assets/icons/arrow-down.svg?inline";
import ISort from "../assets/icons/sort.svg?inline";

export default {
  components: { IArrowDown, ISort },
  props: {
    value: [Number, String],
    items: Array,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    activeItem() {
      return this.items.find((item) => item.value === this.value) || {};
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    selectItem(value) {
      this.$emit("update:value", value);
      this.close();
    },
  },
};
</script>
