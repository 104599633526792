import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useToast } from "@/composables/useToast";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { signMessage } from "@/helpers/sign-message";
import { useWeb3Store } from "@/store/web3";

export const achievementsClaim = async (achievementId) => {
  try {
    const signature = await signMessage("I'm Claiming Achievements");
    if (!signature) return;
    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/achievements/claim",
      method: "POST",
      data: {
        wallet: useWeb3Store().collectionWallet,
        achievementId,
        signature,
        network: Config.network.name,
      },
    });
    useToast().success({ title: "Achievement successfully claimed" });
    return res;
  } catch (err) {
    captureError(err);
  }
};
