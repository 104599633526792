<template>
    <div class="achievements-card" :class="'status-' + status">
        <div class="achievements-card-row">
            <div class="achievements-card-left">
                <div class="achievements-card__title">
                    {{ item.title }}
                    <span
                        class="achievements-card__reward-amount"
                        v-tooltip="{ content: 'Reward: ' + item.reward_amount + ' flag' + (item.reward_amount > 1 ? 's' : ''), distance: 5 }"
                    >
                        <IFlag width="16" />
                        {{ item.reward_amount }}
                    </span>
                </div>
                <div class="achievements-card__description">{{ item.description }}</div>
                <div v-if="item.note" class="achievements-card__description">Note: {{ item.note }}</div>
            </div>
            <div class="achievements-card-right">
                <div class="achievements-card__status">
                    <span 
                        v-if="status !== 3"
                        class="achievements-card__status-progress"
                    >{{ item.progress || 0 }}/{{ item.requirement }}</span>
                    <span v-if="status !== 3" class="achievements-card__status-delimeter">-</span>
                    <span class="achievements-card__status-name">{{ statusName }}  {{ remainingClaimable }}</span>
                </div>
                <div class="achievements-card__action">
                    <div class="achievements-card__claim" v-if="status === 2">
                        <a href="javascript:void(0)" @click.prevent="claim">Claim</a>
                    </div>
                    <a
                        class="achievements-card__cta"
                        :href="item.actionLink"
                        target="_blank"
                        v-if="status < 2"
                    >
                        {{ item.actionName }}
                        <ILink width="18" />
                    </a>
                </div>
            </div>
        </div>
        <div
            class="achievements-card__bg-progress"
            v-if="status == 1"
            :style="{ width: progressPercents + '%' }"
        />
    </div>
</template>
<script setup>import { computed } from "vue-demi";
import IFlag from "@/assets/icons/flag.svg?inline"
import ILink from "@/assets/icons/arrow-right-long.svg?inline"
import { achievementsClaim } from "@/functions/achievements/achievementsClaim"
const props = defineProps({
    item: Object
})
const emit = defineEmits(['refresh'])
const claim = async () => {
    const success = await achievementsClaim(props.item.id)
    if (success) {
        emit('refresh')
    }
}
const status = computed(() => {
    if (props.item.claimed) return 3
    if (props.item.unlocked) return 2
    if (props.item.progress < 1) return 0
    if (props.item.progress < props.item.requirement) return 1
    return 0
})
const statusName = computed(() => {
    if (status.value == 0) return 'Not Started'
    if (status.value == 1) return 'In Progress'
    if (status.value == 2) return 'Completed'
    if (status.value == 3) return 'Claimed'
    return ''
})
const remainingClaimable = computed(() => {
    if(props.item.max_claim) return `(${props.item.max_claim} Remaining)`

    return ''
})
const progressPercents = computed(() => {
    return Math.round(((props.item.progress || 0) / props.item.requirement) * 100)
})
</script>
<style lang="scss">
.achievements-card {
    border-radius: 5px;
    border: 1px solid var(--border);
    box-sizing: border-box;
    padding: 10px;
    background-color: var(--gray-light);
    position: relative;
    &-row {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: stretch;
        z-index: 2;
    }
    &__title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 9px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;
    }
    &__description {
        color: rgb(173, 173, 173);
    }
    &-right {
        width: 300px;
        flex-shrink: 0;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }
    &__reward-amount {
        display: flex;
        align-items: center;
        margin-right: 5px;
        color: var(--yellow);

        svg {
            margin-right: 5px;
        }
    }
    &__action {
        margin-top: auto;
    }
    &__bg-progress {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #0f1d39;
        z-index: 1;
        border-radius: 5px;
    }
    &__cta {
        padding: 3px 0;
        color: #fff;
        display: flex;
        align-items: center;
        text-decoration: none;
        border-bottom: 1px solid var(--accent);
        color: var(--accent);
        box-sizing: border-box;
        margin-top: 5px;
        transition: 0.2s;
        svg {
            transition: transform 0.2s;
            margin-left: 4px;
        }
        &:hover {
            color: #fff;
            svg {
                transform: translateX(3px);
            }
        }
    }
    &__claim {
        a {
            color: #fff;
            padding: 8px 20px;
            display: inline-block;
            border-radius: 5px;
            background-color: var(--purple);
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;
            transition: 0.2s;
            &:hover {
                filter: brightness(0.8);
            }
        }
    }
    &__status {
        display: flex;
        align-items: center;
        &-delimeter {
            margin: 0 5px;
        }
        &-name {
        }
        &-progress {
            font-weight: bold;
        }
    }
    &-left {
        flex: 1;
    }
    &.status-0 {
        background-color: var(--gray-light);
    }
    &.status-0 & {
        &__status-name {
            color: #838dac;
        }
    }
    &.status-1 {
    }
    &.status-1 & {
        &__status-name {
            color: #3072ff;
        }
    }
    &.status-2 {
        background-color: #0e360e;
    }
    &.status-2 & {
        &__status-name {
            color: #3cff3c;
        }
    }
    &.status-3 {
        background-color: #0e360e;
    }
    &.status-3 & {
        &__status-name {
            color: #3cff3c;
        }
    }
    @media screen and (max-width: 576px) {
        &-row {
        }
        &-right {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
</style>