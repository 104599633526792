<template>
    <div class="app-page-header">
        <div>
            <h2 class="app-page-header-title">{{ title }}</h2>
            <div class="app-page-header-description">{{ description }}</div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "vue-demi";
export default defineComponent({
    props: {
        title: String,
        description: String
    },
    components: {},
    setup() {

        return {}
    }
})
</script>
<style lang="scss">
.app-page-header {
    &-title {
        font-size: 32px;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        margin-bottom: 12px;
    }
    &-description {
        font-size: 18px;
        text-transform: uppercase;
        color: #7e7e7e;
    }
    @media screen and (max-width: 600px) {
        &-title {
            font-size: 18px;
            margin-bottom: 5px;
        }
        &-description {
            font-size: 14px;
        }
    }
}
</style>