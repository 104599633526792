<template>
    <div class="achievements-groups">
        <AchievementsGroup v-for="(group, idx) in groups" :key="idx" v-bind="group" @refresh="$emit('refresh')" />
    </div>
</template>
<script setup>
import { computed } from 'vue';
import AchievementsGroup from './AchievementsGroup.vue';
const props = defineProps({ items: Array })
const groups = computed(() => {

    const groupedItems = []
    props.items.forEach(item => {
        (item.tags || []).forEach(itemTag => {
            const groupedItemIdx = groupedItems.findIndex(item => item.title === itemTag)
            if (groupedItemIdx > -1) {
                groupedItems[groupedItemIdx].items.push(item)
            } else {
                groupedItems.push({ title: itemTag, items: [item] })
            }
        })

    })
    return groupedItems
})
</script>
<style lang="scss"></style>