<template>
    <div class="achievements">
        <div class="page-container">
            <div class="achievements-button-back-wrapper">
                <div class="achievements-button-back button-back" @click.prevent="goBack">
                    <IArrowLeft width="32" />
                    <span>Back</span>
                </div>
            </div>
            <APageHeader class="achievements-header" title="Achievements"
                description="You can see your achievements here" />
            <AchievementsFilters v-model:completeness="completeness" v-model:sort="sort" />
            <div class="achievements-loading" v-if="isLoading">
                <img class="loader" src="@/assets/img/spinner-clear.svg" width="100" />
            </div>
            <template v-else>
                <AchievementsGroups :items="items" @refresh="fetchAchievements" v-if="items.length > 0" />
                <div class="achievements-empty" v-else>There is no achievements matching your criteria</div>
            </template>
        </div>
    </div>
</template>
<script setup>
import { useApesStorageApi } from "@/api/useApesStorageApi";
import AchievementsList from "@/components/Achievements/AchievementsList/AchievementsList.vue";
import { usePageWithWallet } from "@/composables/usePageWithWallet";
import { Config } from "@/config";
import { useMethBankStore } from "@/store/meth-bank";
import { useWeb3Store } from "@/store/web3";
import { ref, watch } from "vue-demi";
import APageHeader from "@/components/APageHeader.vue";
import AchievementsGroups from "@/components/Achievements/AchievementsGroup/AchievementsGroups.vue";
import IArrowLeft from "@/assets/icons/arrow-left-long.svg?inline"
import { useRouter } from "vue-router";
import AchievementsFilters from "@/components/Achievements/AchievementsFilters/AchievementsFilters.vue";
const items = ref([])
const completeness = ref('all')
const sort = ref('name')
const isLoading = ref(true)
const fetchAchievements = async () => {
    isLoading.value = true
    let filter = completeness.value
    if (completeness.value === 'archived') {
        filter = 'all'
    }
    const res = await useApesStorageApi({ toast: { error: true } }).exec({
        url: "/achievements/list",
        params: {
            wallet: useWeb3Store().collectionWallet,
            network: Config.network.name,
            sort: sort.value,
            filter: filter
        }
    })
    console.log(res)
    isLoading.value = false
    if (!res) return
    items.value = res
    if (completeness.value === 'archived') {
        items.value = items.value.filter(item => item.distribution_ended)
    }
}
watch(completeness, () => {

    fetchAchievements()
})
watch(sort, () => {
    fetchAchievements()
})
const { isConnected, connect } = usePageWithWallet({
    async onInit(connected) {
        if (connected) {
            useMethBankStore().fetchMethBank()
            fetchAchievements()
        } else {
            fetchAchievements()
        }
    },
    onWalletChange: (isConnected) => {
        if (isConnected) {
            useMethBankStore().fetchMethBank()
            fetchAchievements()
        }

    }
})
const router = useRouter()
const goBack = () => {
    const lastPath = router.options.history.state.back
    if (lastPath) {
        router.back()
    } else {
        router.push({ name: "Collection" })
    }
}
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,700&family=Oxanium:wght@500;700;800&display=swap");

.achievements {
    &-header {
        font-family: var(--font-oxanium);
        margin-bottom: 30px;
    }

    &-button-back {
        &-wrapper {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 10px;
        }
    }

    &-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0;
    }

    &-empty {
        padding: 50px 0;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }
}
</style>