<template>
    <div class="achievements-sort">
        <div class="achievements-sort-title">SORT</div>
        <ASelect :items="options" :value="value" @update:value="$emit('update:value', $event)" />
    </div>
</template>
<script setup>
import ASelect from "@/components/ASelect.vue";
defineProps({ value: String })
defineEmits(['update:value'])
const options = [
    {
        name: "Name",
        value: "name"
    },
    {
        name: "Number of Flags",
        value: "flags"
    },
    {
        name: "Recently Added",
        value: "recent"
    },
    {
        name: "Progress",
        value: "progress"
    },
]
</script>
<style lang="scss">
.achievements-sort {
    &-title {
        opacity: 0.5;
        text-transform: uppercase;
        font-style: 14px;
        font-weight: bold;
        margin-bottom: 3px;
    }
}
</style>