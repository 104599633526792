<template>
    <div class="achievements-filters-complete">
        <div class="achievements-filters-complete-title">State</div>
        <div class="achievements-filters-complete-items">
            <div
                class="achievements-filters-complete-item"
                v-for="(item, idx) in items"
                :key="idx"
                @click.prevent="selectItem(item.value)"
                :class="{ active: value === item.value }"
            >{{ item.name }}</div>
        </div>
    </div>
</template>
<script setup>
const props = defineProps({ value: String })
const emit = defineEmits(['update:value'])
const items = [
    {
        name: "All",
        value: 'all'
    },
    {
        name: "Claimed",
        value: 'claimed'
    },
    {
        name: "Unclaimed",
        value: 'unclaimed'
    },
    {
        name: "Archived",
        value: 'archived'
    },
]
const selectItem = value => {
    emit('update:value', value)
}
</script>
<style lang="scss">
.achievements-filters-complete {
    &-items {
        display: inline-flex;
        border-top: 1px solid var(--border);
        border-bottom: 1px solid var(--border);
        border-radius: 5px;
    }
    &-title {
        margin-bottom: 5px;
        color: rgba(255, 255, 255, 0.5);
        text-transform: uppercase;
        font-weight: bold;
    }
    &-item {
        padding: 5px 14px;
        border-right: 1px solid var(--border);
        cursor: pointer;

        transition: 0.2s;
        &:first-child {
            border-left: 1px solid var(--border);

            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        &:hover {
            background-color: var(--gray-light);
        }
        &.active {
            background-color: var(--primary);
        }
    }
    @media screen and (max-width: 768px) {
        &-title {
            text-align: center;
        }
    }
}
</style>