import { useToast } from "@/composables/useToast";
import { useWeb3Store } from "@/store/web3";
import { web3 } from "@/web3";

export const signMessage = async (message) => {
  try {
    const wallet = useWeb3Store().walletAddress;
    const signature = await web3.eth.personal.sign(message, wallet);
    return signature;
  } catch (err) {
    useToast().error({ title: "Couldn't sign the message" });
    console.error(err);
  }
};
